/* Our Products Styles */
.product-icon {
    color: #007bff; /* Primary color for icons */
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .card:hover .product-icon {
    color: #0056b3; /* Darker shade on hover */
    transform: scale(1.1); /* Slightly larger icon on hover */
  }
  
  .product-feature {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
  
  .card-footer {
    text-align: center;
  }
  
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa; /* Subtle background change on hover */
  }
  