/* NotFound.css */
.not-found-body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #333;
  }
  
  .not-found-container {
    text-align: center;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    margin: 20px;
  }
  
  .error {}
  
  .heading {
    font-size: 96px;
    margin: 0;
    color: #01A59C;
  }
  
  .message {
    font-size: 18px;
    margin: 20px 0;
    color: #666;
  }
  
  .back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #01A59C;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-home:hover {
    background-color: #ff4c4c;
  }
  