/* WhyChooseUs Styles */
.why-choose-us {
  background: #f7f7f7; /* Subtle light background */
}

.card {
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.icon-animate {
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon-animate:hover {
  transform: scale(1.2);
  color: #00D4B4; /* Teal color on hover */
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .card-title {
    font-size: 1.1rem;
  }

  .card-text {
    font-size: 0.9rem;
  }
}
