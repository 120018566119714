/* ColorPredictionGameProduct.css */
.product-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.product-hero {
    margin-bottom: 30px;
}

.product-image {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: block;
}

.product-tagline {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #555;
}

.product-details {
    text-align: left;
}

.product-details h2 {
    margin-bottom: 20px;
}

.product-details ul {
    list-style-type: disc;
    padding-left: 20px;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
    margin-top: 20px;
}

.btn-primary:hover {
    background-color: #0056b3;
}
