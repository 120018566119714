/* Ensure no borders are applied */
header, .navbar, .logo {
  border: none;
  box-shadow: none;
}

/* If needed, add specific styling for img and other elements */
img.logo {
  border: none;
  box-shadow: none;
}

h2 {
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 0;
}