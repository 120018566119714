/* Portfolio Styles */
.project-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .project-img {
    transition: transform 0.3s ease;
  }
  
  .project-card:hover .project-img {
    transform: scale(1.1);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .project-card:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .project-card:hover .text {
    opacity: 1;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 767.98px) {
    .project-card {
      margin-bottom: 20px;
    }
  }
  