/* HeroSection.css */

.hero-container {
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at center, #FF9A00cc, #FF0000cc, #FF9A00cc),
    linear-gradient(135deg, #FF000080, #FF9A0080),
    repeating-linear-gradient(45deg, #ffffff0d 0%, #ffffff 10px, #000000 10px, #0000000d 40px),
    repeating-linear-gradient(90deg, #ffffff0d 0%, #ffffff 10px, #000000 10px, #0000000d 40px),
    repeating-linear-gradient(135deg, #ffffff0d 0%, #ffffff 10px, #000000 10px, #0000000d 40px),
    repeating-linear-gradient(180deg, #ffffff0d 0%, #ffffff 10px, #000000 10px, #0000000d 40px);
  background-size: cover;
  z-index: 0;
  animation: backgroundMove 30s linear infinite;
}

/* Define shapes */
.shape {
  position: absolute;
  z-index: 1;
  animation: floatPath var(--duration, 30s) infinite ease-in-out;
  animation-delay: var(--delay, 0s);
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

/* Floating path animation */
@keyframes floatPath {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  25% {
    transform: translateY(-50px) translateX(50px) rotate(45deg);
  }
  50% {
    transform: translateY(0) translateX(100px) rotate(90deg);
  }
  75% {
    transform: translateY(50px) translateX(50px) rotate(135deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(180deg);
  }
}

/* Content styling */
.hero-content {
  position: relative;
  z-index: 1;
  padding: 20px;
}

.hero-container .company {
  font-size: 3rem;
  font-weight: 700;
  text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.6);
  animation: fadeInDown 1.5s ease-out;
}

.hero-container p {
  font-size: 1.25rem;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
  animation: fadeInUp 1.5s ease-out;
}

.btn-light {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-light:hover {
  background-color: #FF9A00cc;
  color: white;
}

/* Fade-in animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Background movement */
@keyframes backgroundMove {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

/* Scroll Down Indicator */
.scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.scroll-down {
  font-size: 2rem;
  color: white;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.scroll-down:hover {
  transform: translateY(10px);
}

/* Responsive Typography */
@media (max-width: 768px) {
  .hero-container h1 {
    font-size: 2.5rem;
  }
  .hero-container p {
    font-size: 1rem;
  }
}
