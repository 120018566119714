/* Introduction Styles */
.poppy-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.poppy-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa; /* Subtle background change on hover */
}

.icon-highlight {
  color: #007bff; /* Primary color for icons */
  transition: color 0.3s ease, transform 0.3s ease;
}

.poppy-card:hover .icon-highlight {
  color: #0056b3; /* Darker shade on hover */
  transform: scale(1.1); /* Slightly larger icon on hover */
}

.card-title {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .poppy-card {
    margin-bottom: 20px;
  }
}
