/* Navbar.css */

.custom-navbar {
  top: 0;
  border: none;
  box-shadow: none;
  padding: 40px 80px; 
}

.custom-navbar .navbar-brand img {
  height: 60px; /* Adjust this value based on the logo height */
  max-height: 100%; /* Make sure the logo doesn't exceed the navbar height */
  margin-right: 15px;
}

.custom-navbar .navbar-nav .nav-link {
  color: #ffffff;
  transition: color 0.3s ease;
  padding: 8px 12px; /* Adjust padding to fit on smaller screens */
}

.custom-navbar .navbar-nav .nav-link:hover {
  color: rgb(200, 200, 200);
}

/* Ensure the navbar-toggler icon is visible and functional */
.custom-navbar .navbar-toggler {
  border: none;
  color: #333;
  outline: none;
}

/* Adjust visibility and alignment for smaller screens */
@media (max-width: 991px) {
  .custom-navbar .navbar-nav {
    text-align: center;
  }
  .custom-navbar .navbar-collapse {
    padding: 0; /* Ensure no extra padding */
  }
}
